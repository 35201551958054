<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="title big">Welcome to PUDGESHOOK</div>
          <div class="desc">
            Your go-to destination for Dota 2! Browse our selection of high-quality skins to upgrade your collection and enhance your gameplay. Thank you for choosing PUDGESHOOK for all your skin needs. Happy shopping!
          </div>
          <router-link to="/product-list/dota2" class="button">
            <span>Shop Now</span>
          </router-link>
          <div class="img-container">
            <img src="./../assets/hero.png" class="img"/>
          </div>
        </div>
      </div>
      <div class="section arrivals-section">
        <div class="top">
          <div class="title">
            <span>NEW</span> Arrivals
          </div>
          <router-link to="/new-arrivals" class="link">
            <span>View more</span>
          </router-link>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.newArrivals.length" :list="$parent.newArrivals" :tag="'New'" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency" />
        </div>
      </div>
      <Features/>
      <CompactSlider :list="$parent.newArrivals"/>
      <div v-if="dailyOffer.title" class="section daily-offer-section">
        <div class="wrapper" @click="$parent.goToProduct(dailyOffer)">
          <div class="daily-poduct-container">
            <div class="title side-title">
              <span>Daily Offer</span>
            </div>
            <div class="daily-poduct">
              <div class="preview" v-if="dailyOffer.img_url">
                <img :src="imgDomain + dailyOffer.img_url" class="img"/>
              </div>
            </div>
            <div class="right">
              <div class="title small">{{dailyOffer.title}}</div>
              <div class="title price">
                {{dailyOffer.price}}
                <span class="currency">{{currency}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              Sale
            </div>
            <router-link to="/sale" class="link">
              <span>View more</span>
            </router-link>
          </div>
          <SlickItem v-if="$parent.saleProducts.length" :list="$parent.saleProducts" :tag="'Sale'" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency"/>
        </div>
      </div>
      <div class="section more-offers-section">
        <div class="wrapper">
          <div class="left">
            <img src="./../assets/more-offers.png" class="img"/>
          </div>
          <div class="left">
            <div class="title">More than 1K offers</div>
            <ul class="list">
              <li class="item desc">
                <img src="./../assets/wex.png" class="img"/>
                There are many variations of skins to choose from
              </li>
              <li class="item desc">
                <img src="./../assets/exort.png" class="img"/>
                Cheap Dota2 and TF2 skins at a discounted price
              </li>
              <li class="item desc">
                <img src="./../assets/qas.png" class="img"/>
                It is your chance to get epic items!
              </li>
            </ul>
            <router-link to="/product-list/dota2" class="button">
              <span>View all offers</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="section arrivals-section recommended-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              Recommended
            </div>
            <router-link to="/recommend" class="link">
              <span>View more</span>
            </router-link>
          </div>
          <SlickItem v-if="$parent.recommendedProducts.length" :list="$parent.recommendedProducts" :tag="'Recommend'" :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct" :currency="currency"/>
        </div>
      </div>
      <div class="section faq-section">
        <div class="wrapper">
          <div class="title">
            <b>FAQ</b>
          </div>
          <div class="accordion">
            <div 
              :class="['item', {'active': isActivePanel(index)}]"
              v-for="(item, index) in $parent.faq" :key="index"
            >
              <div class="panel" @click="togglePanel(index)">
                <img src="./../assets/minus.svg" class="minus"/>
                <img src="./../assets/plus.svg" class="plus"/>
                <div class="desc main">
                  <span>{{ '0' + (index + 1) }}</span>
                  <b>{{ item.question }}</b>
                </div>
              </div>
              <div class="desc small" v-if="isActivePanel(index)" v-html="item.answer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';
import Features from '../components/Features.vue'
import CompactSlider from '../components/CompactSlider.vue'


export default {
  name: 'Home',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    SlickItem,
    Features,
    CompactSlider
  },
  data: function() {
    return {
      imgDomain: '',
      dailyOffer: {},
      activePanels: []
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getDailyOffer();
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
    getDailyOffer() {
      this.$http.get(process.env.VUE_APP_API + 'items/daily')
      .then((res) => {
        console.log(res)
        this.dailyOffer = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    }
  }
}
</script>